import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-joliet-illinois.png'
import image0 from "../../images/cities/scale-model-of-old-joliet-prison-in-joliet-illinois.png"
import image1 from "../../images/cities/scale-model-of-joliet-iron-works-park-in-joliet-illinois.png"
import image2 from "../../images/cities/scale-model-of-slovenian-women's-union-and-heritage-museum-in-joliet-illinois.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Joliet'
            state='Illinois'
            image={image}
            lat='41.525031'
            lon='-88.08172509999997'
            attractions={ [{"name": "Old Joliet Prison", "vicinity": "1125 Collins St, Joliet", "types": ["point_of_interest", "establishment"], "lat": 41.5469082, "lng": -88.07425969999997}, {"name": "Joliet Iron Works Park", "vicinity": "31 Columbia St, Joliet", "types": ["park", "point_of_interest", "establishment"], "lat": 41.5366495, "lng": -88.0800332}, {"name": "Slovenian Women's Union and Heritage Museum", "vicinity": "431 N Chicago St, Joliet", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.532317, "lng": -88.081975}] }
            attractionImages={ {"Old Joliet Prison":image0,"Joliet Iron Works Park":image1,"Slovenian Women's Union and Heritage Museum":image2,} }
       />);
  }
}